import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.png'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import ServicesDetails from '../components/Services/ServicesDetails'
import Pricing from '../components/Services/Pricing'
import SingularityStartup from '../components/Home/SingularityStartup'
import '../styles/Services/Services.css'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Services: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Grid container>
        <img src={PageHeroBg} alt="Logo" className={'services_image'} />
        <Grid item className={'services_grid'} xs={12}>
          <div className={'services_subheading'}>
            {t('services_screen_subheading')}
          </div>
          <div className={'services_heading'}>
            {t('services_screen_heading')}
          </div>
          <div className={'services_text'}>{t('services_screen_text')}</div>
        </Grid>
        <ServicesDetails />
        <Pricing />
        <SingularityStartup />
        <div style={{ marginTop: '10rem' }}></div>
        <ContactUs />
        {isMobile ? <div></div> : <Icons />}
      </Grid>
    </>
  )
}

export default Services
