import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Button,
  Typography,
  Icon,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import ProcessDots1 from '../../assets/process_dots_1.png'
import ProcessDots2 from '../../assets/process_dots_2.png'

interface Tier {
  id: number
  heading: string
  price: number
  max_price: number
  multiplier: number
  type: string
  payment_type: string
  text: string
  for_who: string
}

const SingularityStartupPricingView: React.FC = () => {
  const { t } = useTranslation()
  const [free, setFree] = useState<number>(0)
  const [standard, setStandard] = useState<number>(0)
  const [pro, setPro] = useState<number>(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [hideMaxLabels, setHideMaxLabels] = useState<{
    [key: number]: boolean
  }>({})

  const tiers: Tier[] = [
    {
      id: 1,
      heading: 'Free',
      price: free,
      max_price: 5000,
      multiplier: 0.2,
      type: 'free',
      payment_type: 'MONTHLY',
      text: 'Singularity’s FREE tier is suitable for smaller apps and teams operating on a strict budget. While not all features are accessible, it serves those willing to wait for support.',
      for_who: 'Small or limited apps',
    },
    {
      id: 2,
      heading: 'Standard',
      price: standard,
      max_price: 200000,
      multiplier: 0.5,
      type: 'standard',
      payment_type: 'MONTHLY',
      text: 'Singularity’s STANDARD tier provides access to most features, alongside regular support. This tier is suitable for teams looking to harness the power of AI and voice coding at a reasonable price.',
      for_who: 'Regular apps',
    },
    {
      id: 3,
      heading: 'Pro',
      price: pro,
      max_price: 500000,
      multiplier: 0.7,
      type: 'pro',
      payment_type: 'MONTHLY',
      text: "Singularity’s PRO tier grants access to all features without restrictions. Additionally, you'll be given priority support from our team of skilled developers.",
      for_who: 'Big or state-of-the-art apps',
    },
  ]

  const customTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          // You will manage hiding label at a per-slider basis, not here.
          markLabel: { color: '#AAA' },
          valueLabel: {
            color: '#0076FF',
            backgroundColor: 'transparent',
            top: 50,
          },
        },
      },
    },
  })

  function formatValue(parsedNumber: number) {
    if (parsedNumber >= 1000) {
      return parsedNumber / 1000 + 'K'
    }
    return parsedNumber.toString()
  }

  const handleSliderChange = (
    event: any,
    value: number | number[],
    tier: Tier,
  ) => {
    const closeToMax =
      typeof value === 'number' &&
      tier.max_price - value <= 0.05 * tier.max_price
    setHideMaxLabels({
      ...hideMaxLabels,
      [tier.id]: closeToMax,
    })
    if (typeof value === 'number') {
      switch (tier.type) {
        case 'free':
          setFree(value)
          break
        case 'standard':
          setStandard(value)
          break
        case 'pro':
          setPro(value)
          break
        default:
          break
      }
    }
  }

  if (isMobile) {
    return (
      <ThemeProvider theme={customTheme}>
        <Box sx={{ display: 'flex', marginTop: '1rem' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                fontFamily: 'Axiforma',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '4rem .5rem',
              }}
            >
              <div
                style={{
                  fontFamily: 'Axiforma',
                  fontSize: '48px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '60px',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_pricing_sliders_heading')}
              </div>
              <div
                style={{
                  fontFamily: 'Axiforma',
                  textAlign: 'center',
                  marginTop: '1rem',
                  backgroundColor: '#0076FF',
                  height: '2px',
                  width: '64px',
                }}
              ></div>
              <div
                style={{
                  fontFamily: 'Axiforma',
                  fontSize: '16px',
                  padding: '0 1rem',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '1rem',
                  textAlign: 'center',
                  color: '#AAA',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_pricing_sliders_text')}
              </div>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          spacing={3}
          style={{ marginTop: '4rem', padding: '0 2rem', marginBottom: '2rem' }}
        >
          {tiers.map((tier, index) => (
            <Grid item xs={12} key={index}>
              <Card
                style={{
                  backgroundColor: '#171717',
                  color: 'white',
                  padding: '1rem',
                }}
              >
                <CardContent>
                  {/* Top row with heading on left and price on right */}
                  <Grid container alignItems="center" justifyContent="start">
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontFamily: 'Axiforma',
                          fontSize: '24px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: 'normal',
                        }}
                        component="div"
                      >
                        {tier.heading}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Second row with new text on left and payment type on right */}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="start"
                    style={{ marginTop: '1rem' }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          fontFamily: 'Axiforma',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          lineHeight: '24px',
                          color: '#AAA',
                        }}
                      >
                        {tier.text}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'left', marginTop: '1rem' }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: 'normal',
                        color: '#0076FF',
                      }}
                      component="div"
                    >
                      + ${Math.round(tier.price * tier.multiplier)}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'start', marginTop: '1rem' }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: 'normal',
                        letterSpacing: '0.48px',
                      }}
                    >
                      {tier.payment_type}
                    </Typography>
                  </Grid>

                  {/* Slider */}
                  <Box mt={2}>
                    <Slider
                      onChange={(event, value) =>
                        handleSliderChange(event, value, tier)
                      }
                      valueLabelDisplay="on"
                      min={0}
                      valueLabelFormat={(value) => formatValue(value)}
                      max={tier.max_price}
                      step={100}
                      marks={[
                        { value: 0, label: '0' },
                        {
                          value: tier.max_price,
                          label: hideMaxLabels[tier.id]
                            ? ''
                            : formatValue(tier.max_price),
                        },
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex', marginTop: '4rem' }}>
        <Grid container>
          <img
            src={ProcessDots1}
            alt="Dots1"
            style={{ position: 'absolute', top: 120, left: 0, zIndex: -1 }}
          />
          <Grid
            item
            xs={12}
            style={{
              fontFamily: 'Axiforma',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10rem',
              paddingBottom: '4rem',
            }}
          >
            <div
              style={{
                fontFamily: 'Axiforma',
                fontSize: '48px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '60px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_pricing_sliders_heading')}
            </div>
            <div
              style={{
                fontFamily: 'Axiforma',
                textAlign: 'center',
                marginTop: '1rem',
                backgroundColor: '#0076FF',
                height: '2px',
                width: '64px',
              }}
            ></div>
            <div
              style={{
                fontFamily: 'Axiforma',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
                marginTop: '1rem',
                textAlign: 'center',
                color: '#AAA',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_pricing_sliders_text')}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        style={{ marginTop: '4rem', padding: '0 16rem', marginBottom: '2rem' }}
      >
        <img
          src={ProcessDots2}
          alt="Dots2"
          style={{ position: 'absolute', top: 470, right: 40, zIndex: -1 }}
        />
        {tiers.map((tier, index) => (
          <Grid item xs={12} key={index}>
            <Card
              style={{
                backgroundColor: '#171717',
                color: 'white',
                padding: '1rem',
              }}
            >
              <CardContent>
                {/* Top row with heading on left and price on right */}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      }}
                      component="div"
                    >
                      {tier.heading}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: 'normal',
                        color: '#0076FF',
                      }}
                      component="div"
                    >
                      + ${Math.round(tier.price * tier.multiplier)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Second row with new text on left and payment type on right */}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '24px',
                        color: '#AAA',
                      }}
                    >
                      {tier.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography
                      style={{
                        fontFamily: 'Axiforma',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '300',
                        lineHeight: 'normal',
                        letterSpacing: '0.48px',
                      }}
                    >
                      {tier.payment_type}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Slider */}
                <Box mt={2}>
                  <Slider
                    onChange={(event, value) =>
                      handleSliderChange(event, value, tier)
                    }
                    valueLabelDisplay="on"
                    min={0}
                    valueLabelFormat={(value) => formatValue(value)}
                    max={tier.max_price}
                    step={100}
                    marks={[
                      { value: 0, label: '0' },
                      {
                        value: tier.max_price,
                        label: hideMaxLabels[tier.id]
                          ? ''
                          : formatValue(tier.max_price),
                      },
                    ]}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  )
}

export default SingularityStartupPricingView
