import React from 'react'
import Grid from '@mui/material/Grid'
import { ServiceData } from './ServicesData'
import { MobileServiceData } from './MobileServicesData'
import { useMediaQuery, useTheme } from '@mui/material'

const ServicesDetails: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        <Grid
          container
          sx={{
            backgroundColor: '#000',
            width: '100%',
            padding: '1rem',
            overflow: 'hidden',
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'grid', alignItems: 'center' }}>
            {MobileServiceData.map((service, index) => (
              <Grid
                key={index}
                container
                sx={{
                  textAlign: 'start',
                  marginTop: '4rem',
                  padding: '0 1rem',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      fontSize: '32px',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      lineHeight: '130%',
                      color: '#FFF',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {service.heading}
                  </div>
                  <div
                    style={{
                      marginTop: '1rem',
                      backgroundColor: '#0076FF',
                      height: '2px',
                      width: '64px',
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: '24px',
                      marginTop: '2rem',
                      textAlign: 'start',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {service.text}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    src={service.background}
                    alt=""
                    style={{
                      maxWidth: '70%',
                      height: 'auto',
                      marginLeft: '3rem',
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        {ServiceData.map((service, index) => (
          <Grid
            container
            key={index}
            style={{
              flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
              background:
                index === 0
                  ? 'linear-gradient(to bottom, transparent, #080808)'
                  : index % 2 === 0
                  ? '#080808'
                  : '#0F0F0F',
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: index % 2 === 0 ? 'flex-end' : 'flex-start',
                padding: 16,
              }}
            >
              <h2
                style={{
                  fontSize: '48px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '60px',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                  textAlign: 'start',
                  marginRight:
                    index === 0
                      ? '4rem'
                      : index === 2
                      ? '4.5rem'
                      : index === 4
                      ? '8rem'
                      : '',
                }}
              >
                {service.heading}
              </h2>
              <div
                style={{
                  backgroundColor: '#0076FF',
                  height: '2px',
                  marginTop: '-2rem',
                  width: '64px',
                  textAlign: 'start',
                  marginRight:
                    index === 0
                      ? '19.5rem'
                      : index === 2
                      ? '19.2rem'
                      : index === 4
                      ? '20rem'
                      : '',
                }}
              ></div>
              <p
                style={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '2rem',
                  color: '#AAA',
                  whiteSpace: 'pre-line',
                  textAlign: 'start',
                }}
              >
                {service.text}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end',
                justifyContent: 'center',
                padding: 12,
              }}
            >
              <img
                src={service.background}
                alt={service.background}
                style={{ width: '70%', marginTop: '-8rem' }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ServicesDetails
