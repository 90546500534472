import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectData } from './ProjectData'
import { Grid } from '@mui/material'
import ArrowForward from '@mui/icons-material/ArrowForward'
import '../../styles/Work/ProjectsOverview.css'
import { useTranslation } from 'react-i18next'

const ProjectOverview: React.FC = () => {
  const { t } = useTranslation()
  const router = useNavigate()

  const [projects, setProjects] = useState<{ salary: number; name: string }[]>(
    [],
  )

  const handleProjectClick = (id: number) => {
    router(`/project/${id}`)
  }

  return (
    <>
      <Grid
        container
        className={'project_container'}
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        {ProjectData.map((project) => (
          <Grid item xs={12} md={6} lg={6} key={project.id}>
            <div
              className={'project_background'}
              style={{ backgroundImage: `url(${project.color})` }}
            >
              <img src={project.icon} alt={project.heading} />
              <div className={'project_heading'}>{project.heading}</div>
              <div className={'project_subheading'}>{project.subHeading}</div>
              <div
                className={'project_button'}
                onClick={() => handleProjectClick(project.id)}
              >
                <ArrowForward className={'project_icon'} />
                {t('project_overview_button')}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ProjectOverview
