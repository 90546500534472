import { Button, useTheme, useMediaQuery } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import Logo from '../assets/singularity-logo.png'
import { useTranslation } from 'react-i18next'
import '../i18n'

const Footer = () => {
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t, i18n } = useTranslation()

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem('language', language) // Save language in localStorage
  }

  const button_style = {
    padding: '20px 24px',
    textAlign: 'center',
    borderRadius: '0px',
    border: '1px solid #FFF',
    color: '#FFF',
    marginLeft: 'auto',
    textTransform: 'initial',
  }

  if (isMobile) {
    return (
      <>
        <div
          style={{
            bottom: 0,
            width: '100%',
            backgroundColor: '#0F0F0F',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '490px',
            justifyContent: 'space-between',
          }}
        >
          <img src={Logo} alt="Logo" style={{ marginTop: '4rem' }} />
          <div>
            <nav>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '2rem',
                  marginBottom: '4rem',
                }}
              >
                {location.pathname.startsWith('/singularity-startup') ? (
                  <>
                    <Link
                      to="/singularity-startup/pricing"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link5')}
                    </Link>
                    <Link
                      to="/singularity-startup/process"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link6')}
                    </Link>
                    <Link
                      to="/contact"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link4')}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/our-work"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link1')}
                    </Link>
                    <Link
                      to="/about"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link2')}
                    </Link>
                    <Link
                      to="/services"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link3')}
                    </Link>
                    <Link
                      to="/contact"
                      style={{
                        color: 'white',
                        opacity: '0.8',
                        textDecoration: 'none',
                      }}
                    >
                      {t('singularity_link4')}
                    </Link>
                  </>
                )}
                {location.pathname.startsWith('/singularity-startup') ? (
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" sx={button_style}>
                      {t('singularity_button2')}
                      <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                    </Button>
                  </Link>
                ) : (
                  <Link
                    to="/singularity-startup"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant="outlined" sx={button_style}>
                      {t('singularity_button1')}
                      <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                    </Button>
                  </Link>
                )}
              </div>
            </nav>
            {/*<div style={{ marginTop: '1rem', marginBottom: '1rem' }}>*/}
            {/*    <label>{t('Change Language: ')}</label>*/}
            {/*    <select*/}
            {/*        onChange={(e) => changeLanguage(e.target.value)}*/}
            {/*        value={i18n.language}*/}
            {/*    >*/}
            {/*        <option value="en">English</option>*/}
            {/*        <option value="sk">Slovak</option>*/}
            {/*    </select>*/}
            {/*</div>*/}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div
        style={{
          bottom: 0,
          width: '100%',
          backgroundColor: '#0F0F0F',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          height: '262px',
          alignItems: 'center',
        }}
      >
        <img src={Logo} alt="Logo" style={{ paddingLeft: '6rem' }} />
        <div>
          <nav
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '6rem',
            }}
          >
            {location.pathname.startsWith('/singularity-startup') ? (
              <>
                <Link
                  to="/singularity-startup/pricing"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Pricelist
                </Link>
                <Link
                  to="/singularity-startup/process"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Process
                </Link>
                <Link
                  to="/contact"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Contact us
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/our-work"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Our work
                </Link>
                <Link
                  to="/about"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  About us
                </Link>
                <Link
                  to="/services"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Services
                </Link>
                <Link
                  to="/contact"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    marginRight: '2rem',
                    opacity: '0.8',
                  }}
                >
                  Contact
                </Link>
              </>
            )}
            {location.pathname.startsWith('/singularity-startup') ? (
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button variant="outlined" sx={button_style}>
                  {t('singularity_button2')}
                  <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                </Button>
              </Link>
            ) : (
              <Link
                to="/singularity-startup"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" sx={button_style}>
                  {t('singularity_button1')}
                  <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                </Button>
              </Link>
            )}
          </nav>
          {/*<div>*/}
          {/*    <label>{t('Change Language: ')}</label>*/}
          {/*    <select*/}
          {/*        onChange={(e) => changeLanguage(e.target.value)}*/}
          {/*        value={i18n.language}*/}
          {/*    >*/}
          {/*        <option value="en">English</option>*/}
          {/*        <option value="sk">Slovak</option>*/}
          {/*    </select>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default Footer

// Treba to zarovnať na šírku stránky. Inak výška pätičky má byť 262px (button by mal byť vysoký 62px + 100 px hore a dole).
