import React from 'react'
import { useParams } from 'react-router-dom'
import { Button, Grid, Box, useTheme, useMediaQuery } from '@mui/material'
import { ProjectData } from './ProjectData'
import { MobileProjectData } from './MobileProjectData'
import NotFound from '../../pages/NotFound'
import ContactUs from '../../components/Home/ContactUs'
import { useNavigate } from 'react-router-dom'
import ArrowForward from '@mui/icons-material/ArrowForward'
import '../../styles/Work/ProjectsDetails.css'
import { useTranslation } from 'react-i18next'

const ProjectDetails: React.FC = () => {
  const { t } = useTranslation()
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleButtonClick = () => {
    router('/our-work')
  }

  const handleProjectClick = (id: number) => {
    router(`/project/${id}`)
  }

  const { id } = useParams<{ id?: string }>()
  const projectIndex = id ? parseInt(id, 10) : -1

  // Mobile projects
  var mobile_project
  var other_mobile_projects = []
  for (let i = 0; i < MobileProjectData.length; i++) {
    if (projectIndex === MobileProjectData[i].id) {
      mobile_project = MobileProjectData[i]
    } else if (projectIndex !== MobileProjectData[i].id) {
      other_mobile_projects.push(MobileProjectData[i])
    }
  }

  //  Desktop projects
  var project
  var other_projects = []
  for (let i = 0; i < ProjectData.length; i++) {
    if (projectIndex === ProjectData[i].id) {
      project = ProjectData[i]
    } else if (projectIndex !== ProjectData[i].id) {
      other_projects.push(ProjectData[i])
    }
  }

  const max_items = 2

  if (isMobile) {
    return (
      <>
        {mobile_project ? (
          <>
            <Grid container className={'details_container'}>
              <img
                src={mobile_project.background}
                alt="Background"
                className={'details_image'}
              />
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img src={mobile_project.icon} alt="Icon" />
                <div className={'details_heading'}>
                  {mobile_project.heading}
                </div>
                <div className={'details_subheading'}>
                  {mobile_project.subHeading}
                </div>
              </Grid>
            </Grid>
            <Grid container className={'details_grid_container'}>
              <Grid item className={'details_grid_item'} xs={12}>
                <div className={'details_content_heading'}>
                  {t('project_details_challenge_heading')}
                </div>
                <div className={'details_content_divider'}></div>
                <div className={'details_content_subheading'}>
                  {mobile_project.challenge}
                </div>
              </Grid>
            </Grid>

            <Box
              sx={{
                alignItems: 'center',
                display: 'grid',
                backgroundColor: '#000',
              }}
            >
              <Grid
                container
                sx={{
                  textAlign: 'center',
                  marginTop: '6rem',
                  padding: '0 1rem',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: '32px',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      lineHeight: '110%',
                      color: '#FFF',
                      whiteSpace: 'pre-line',
                      padding: '12px',
                    }}
                  >
                    {t('project_details_idea_heading')}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#0076FF',
                      marginBottom: '1rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      height: '2px',
                      width: '64px',
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '2rem', padding: '0 1rem' }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: '24px',
                      color: '#AAA',
                      padding: '12px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {mobile_project.idea}
                  </div>
                </Grid>
              </Grid>
            </Box>
            {mobile_project.ideaImage ? (
              <>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#000',
                    padding: '1rem',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      textAlign: 'center',
                      marginTop: '2rem',
                      padding: '0 1rem',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={mobile_project.ideaImage}
                        alt="Idea image"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <div></div>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'grid',
                backgroundColor: '#000',
                padding: '0 1rem 2rem 1rem',
              }}
            >
              <Grid
                container
                sx={{
                  textAlign: 'center',
                  marginTop: '4rem',
                  padding: '0 1rem',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: '32px',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      lineHeight: '110%',
                      color: '#FFF',
                      whiteSpace: 'pre-line',
                      padding: '12px',
                    }}
                  >
                    {t('project_details_solution_heading')}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#0076FF',
                      marginBottom: '1rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      height: '2px',
                      width: '64px',
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '1rem', padding: '0 1rem' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'start',
                  }}
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: '#AAA',
                      padding: '1rem 0.5rem',
                    }}
                  >
                    {mobile_project.solution}
                  </div>
                </Grid>
              </Grid>
            </Box>
            {mobile_project.solutionImage ? (
              <>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#000',
                    padding: '1rem',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      textAlign: 'center',
                      marginTop: '2rem',
                      padding: '0 2rem',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={mobile_project.solutionImage}
                        alt="Solution image"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <div></div>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'grid',
                backgroundColor: '#0F0F0F',
                padding: '0 1rem 2rem 1rem',
              }}
            >
              <Grid container sx={{ marginTop: '1rem', padding: '0 0rem' }}>
                <Grid item xs={12}>
                  <div
                    style={{
                      fontSize: '26px',
                      fontWeight: 400,
                      textAlign: 'center',
                      fontStyle: 'normal',
                      lineHeight: '110%',
                      color: '#FFF',
                      whiteSpace: 'pre-line',
                      padding: '12px',
                    }}
                  >
                    {t('project_details_other_projects_heading')}
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#0076FF',
                      marginBottom: '1rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '.5rem',
                      height: '2px',
                      width: '64px',
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: '300',
                      lineHeight: '24px',
                      fontStyle: 'normal',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                      padding: '1rem 1.5rem',
                    }}
                  >
                    {mobile_project.otherProjectsPromo}
                  </div>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: '1rem', padding: '0 1rem' }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={handleButtonClick}
                    variant="outlined"
                    sx={{
                      padding: '20px 24px',
                      textAlign: 'center',
                      marginTop: '2rem',
                      borderRadius: '0px',
                      border: '1px solid #FFF',
                      color: '#FFF',
                      textTransform: 'initial',
                    }}
                  >
                    {t('project_details_other_projects_button')}
                    <ArrowForward style={{ marginLeft: '1rem' }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding: '2rem 1rem', backgroundColor: '#0F0F0F' }}>
              <Grid container>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={2}
                >
                  {other_mobile_projects.map((project, index) => (
                    <Grid item xs={12} md={6} lg={6} key={project.id}>
                      {index < max_items ? (
                        <div
                          style={{
                            height: '85%',
                            padding: '2rem',
                            margin: '2rem 0',
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                            backgroundImage: `url(${project.color})`,
                            backgroundSize: 'cover',
                          }}
                        >
                          <img src={project.icon} />
                          <div
                            style={{
                              marginTop: '3rem',
                              color: '#FFF',
                              fontSize: '24px',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {project.heading}
                          </div>
                          <div
                            style={{
                              color: '#AAA',
                              marginTop: '1rem',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 300,
                              lineHeight: '24px',
                              lineBreak: 'auto',
                              maxWidth: '45ch',
                            }}
                          >
                            {project.subHeading}
                          </div>
                          <div
                            onClick={() => handleProjectClick(project.id)}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 0 0',
                              marginTop: '4rem',
                            }}
                          >
                            <ArrowForward
                              style={{
                                padding: '6px',
                                backgroundColor: '#0076FF',
                                borderRadius: '100px',
                              }}
                            />
                            {t('home_screen_our_work_card2_button')}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
            <ContactUs />
          </>
        ) : (
          <NotFound />
        )}
      </>
    )
  }

  return (
    <>
      {project ? (
        <>
          <Grid container className={'details_container'}>
            <img
              src={project.background}
              alt="Background"
              className={'details_image'}
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <img src={project.icon} alt="Icon" />
              <div className={'details_heading'}>{project.heading}</div>
              <div className={'details_subheading'}>{project.subHeading}</div>
            </Grid>
          </Grid>
          <Grid container className={'details_grid_container'}>
            <Grid item className={'details_grid_item'} xs={12}>
              <div className={'details_content_heading'}>
                {t('project_details_challenge_heading')}
              </div>
              <div className={'details_content_divider'}></div>
              <div className={'details_content_subheading'}>
                {project.challenge}
              </div>
            </Grid>
          </Grid>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              backgroundColor: '#000',
              padding: '4rem',
            }}
          >
            <Grid
              container
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '4rem',
                padding: '0 2rem',
                width: '80%',
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    fontSize: '48px',
                    fontWeight: '400',
                    lineHeight: '60px',
                    fontStyle: 'normal',
                    color: '#FFF',
                    whiteSpace: 'pre-line',
                    padding: '12px',
                  }}
                >
                  {t('project_details_idea_heading')}
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#0076FF',
                    marginBottom: '1rem',
                    height: '2px',
                    width: '64px',
                    marginLeft: '.5rem',
                  }}
                ></div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: '300',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    color: '#AAA',
                    padding: '12px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {project.idea}
                </div>
              </Grid>
            </Grid>
          </Box>
          {project.ideaImage ? (
            <>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: '#000',
                  padding: '4rem',
                }}
              >
                <Grid
                  container
                  sx={{
                    textAlign: 'center',
                    marginTop: '2rem',
                    padding: '0 2rem',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img src={project.ideaImage} alt="Idea image" />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <div></div>
          )}

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              backgroundColor: '#000',
              padding: '4rem',
            }}
          >
            <Grid
              container
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '4rem',
                padding: '0 2rem',
                width: '80%',
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    fontSize: '48px',
                    fontWeight: '400',
                    lineHeight: '60px',
                    fontStyle: 'normal',
                    color: '#FFF',
                    whiteSpace: 'pre-line',
                    padding: '12px',
                  }}
                >
                  {t('project_details_solution_heading')}
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#0076FF',
                    marginBottom: '1rem',
                    height: '2px',
                    width: '64px',
                    marginLeft: '1rem',
                  }}
                ></div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: '300',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    color: '#AAA',
                    padding: '12px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {project.solution}
                </div>
              </Grid>
            </Grid>
          </Box>
          {project.solutionImage ? (
            <>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor: '#000',
                  padding: '4rem',
                }}
              >
                <Grid
                  container
                  sx={{
                    textAlign: 'center',
                    marginTop: '2rem',
                    padding: '0 2rem',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img src={project.solutionImage} alt="Solution image" />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <div></div>
          )}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              backgroundColor: '#0F0F0F',
              padding: '0 4rem 2rem 4rem',
            }}
          >
            <Grid container sx={{ marginTop: '4rem', padding: '0 2rem' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    fontSize: '48px',
                    fontWeight: '400',
                    lineHeight: '60px',
                    fontStyle: 'normal',
                    color: '#FFF',
                    whiteSpace: 'pre-line',
                    padding: '12px',
                  }}
                >
                  {t('project_details_other_projects_heading')}
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#0076FF',
                    marginBottom: '1rem',
                    marginLeft: '1rem',
                    height: '2px',
                    width: '64px',
                  }}
                ></div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: '300',
                    lineHeight: '24px',
                    fontStyle: 'normal',
                    color: '#AAA',
                    whiteSpace: 'pre-line',
                    padding: '12px',
                  }}
                >
                  {project.otherProjectsPromo}
                </div>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '4rem', padding: '0 2rem' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                }}
              >
                <Button
                  onClick={handleButtonClick}
                  variant="outlined"
                  sx={{
                    padding: '20px 24px',
                    textTransform: 'initial',
                    textAlign: 'center',
                    marginTop: '2rem',
                    borderRadius: '0px',
                    border: '1px solid #FFF',
                    color: '#FFF',
                  }}
                >
                  {t('project_details_other_projects_button')}
                  <ArrowForward style={{ marginLeft: '1rem' }} />
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: '0 6rem 4rem 6rem', backgroundColor: '#0F0F0F' }}>
            <Grid container>
              <Grid
                container
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
              >
                {other_projects.map((project, index) => (
                  <Grid item xs={12} md={6} lg={6} key={project.id}>
                    {index < max_items ? (
                      <div
                        style={{
                          height: '85%',
                          padding: '2rem',
                          margin: '2rem 0',
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                          backgroundImage: `url(${project.color})`,
                          backgroundSize: 'cover',
                        }}
                      >
                        <img src={project.icon} />
                        <div
                          style={{
                            marginTop: '3rem',
                            color: '#FFF',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {project.heading}
                        </div>
                        <div
                          style={{
                            color: '#AAA',
                            marginTop: '1rem',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '24px',
                            lineBreak: 'auto',
                            maxWidth: '45ch',
                          }}
                        >
                          {project.subHeading}
                        </div>
                        <div
                          onClick={() => handleProjectClick(project.id)}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            flex: '1 0 0',
                            marginTop: '4rem',
                          }}
                        >
                          <ArrowForward
                            style={{
                              padding: '6px',
                              backgroundColor: '#0076FF',
                              borderRadius: '100px',
                            }}
                          />
                          {t('home_screen_our_work_card2_button')}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          <ContactUs />
        </>
      ) : (
        <NotFound />
      )}
    </>
  )
}

export default ProjectDetails
