import React from 'react'
import Grid from '@mui/material/Grid'
import ApplicationRefactoringIcon from '../../assets/aplication-refactoring-icon.png'
import ArchitectureReviewIcon from '../../assets/architecture-review-icon.png'
import ConsultingIcon from '../../assets/consulting-icon.png'
import SoftwareDevIcon from '../../assets/software-dev-icon.png'
import SoftwareMaintenanceIcon from '../../assets/software-maintenance-icon.png'
import { useTranslation } from 'react-i18next'
import { Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import '../../styles/Home/Services.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const Services: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const router = useNavigate()
  const handleButtonClick = () => {
    router('/services')
  }

  if (isMobile) {
    return (
      <>
        <Grid container className={'services_container'}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={'services_grid'}
          >
            <div className={'services_subheading'}>
              {t('home_screen_services_subheading')}
            </div>
            <div className={'services_heading'}>
              {t('home_screen_services_heading')}
            </div>
            <div className={'services_divider'}></div>
          </Grid>

          <Grid container className={'services_items'}>
            <Grid item xs={12}>
              <img
                src={SoftwareDevIcon}
                alt="SoftwareDevIcon"
                className={'home_services_image'}
              />
            </Grid>
            <Grid item xs={12} className={'services_texts'}>
              <Typography className={'services_items_heading'}>
                {t('home_screen_services_software_development_heading')}
              </Typography>
              <Typography className={'services_items_text'}>
                {t('home_screen_services_software_development_text')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <img
                src={ApplicationRefactoringIcon}
                alt="ApplicationRefactoringIcon"
                className={'home_services_image'}
              />
            </Grid>
            <Grid item xs={12} className={'services_texts'}>
              <Typography className={'services_items_heading'}>
                {t('home_screen_services_application_refactoring_heading')}
              </Typography>
              <Typography className={'services_items_text'}>
                {t('home_screen_services_application_refactoring_text')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <img
                src={ArchitectureReviewIcon}
                alt="ArchitectureReviewIcon"
                className={'home_services_image'}
              />
            </Grid>
            <Grid item xs={12} className={'services_texts'}>
              <Typography className={'services_items_heading'}>
                {t('home_screen_services_architecture_review_heading')}
              </Typography>
              <Typography className={'services_items_text'}>
                {t('home_screen_services_architecture_review_text')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <img
                src={SoftwareMaintenanceIcon}
                alt="SoftwareMaintenanceIcon"
                className={'home_services_image'}
              />
            </Grid>
            <Grid item xs={12} className={'services_texts'}>
              <Typography className={'services_items_heading'}>
                {t('home_screen_services_software_maintenance_heading')}
              </Typography>
              <Typography className={'services_items_text'}>
                {t('home_screen_services_software_maintenance_text')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <img src={ConsultingIcon} alt="ConsultingIcon" />
            </Grid>
            <Grid item xs={12} className={'services_texts'}>
              <Typography className={'services_items_heading'}>
                {t('home_screen_services_consulting_heading')}
              </Typography>
              <Typography className={'services_items_text'}>
                {t('home_screen_services_consulting_text')}
              </Typography>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={12} className={'services_button'}>
              <Button
                onClick={handleButtonClick}
                variant="outlined"
                sx={{
                  padding: '20px 24px',
                  textAlign: 'center',
                  borderRadius: '0px',
                  border: '1px solid #FFF',
                  color: '#FFF',
                }}
              >
                {t('home_screen_services_button')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container className={'services_container'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={'services_grid'}
        >
          <div className={'services_subheading'}>
            {t('home_screen_services_subheading')}
          </div>
          <div className={'services_heading'}>
            {t('home_screen_services_heading')}
          </div>
          <div className={'services_divider'}></div>
        </Grid>

        <Grid
          container
          className={'services_items'}
          justifyContent="stretch"
          alignItems="stretch"
        >
          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
            <img
              src={SoftwareDevIcon}
              alt="SoftwareDevIcon"
              className={'home_services_image'}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_texts'}
          >
            <Typography className={'services_items_heading'}>
              {t('home_screen_services_software_development_heading')}
            </Typography>
            <Typography className={'services_items_text'}>
              {t('home_screen_services_software_development_text')}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
            <img
              src={ApplicationRefactoringIcon}
              alt="ApplicationRefactoringIcon"
              className={'home_services_image'}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_texts'}
          >
            <Typography className={'services_items_heading'}>
              {t('home_screen_services_application_refactoring_heading')}
            </Typography>
            <Typography className={'services_items_text'}>
              {t('home_screen_services_application_refactoring_text')}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
            <img
              src={ArchitectureReviewIcon}
              alt="ArchitectureReviewIcon"
              className={'home_services_image'}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_texts'}
          >
            <Typography className={'services_items_heading'}>
              {t('home_screen_services_architecture_review_heading')}
            </Typography>
            <Typography className={'services_items_text'}>
              {t('home_screen_services_architecture_review_text')}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
            <img
              src={SoftwareMaintenanceIcon}
              alt="SoftwareMaintenanceIcon"
              className={'home_services_image'}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_texts'}
          >
            <Typography className={'services_items_heading'}>
              {t('home_screen_services_software_maintenance_heading')}
            </Typography>
            <Typography className={'services_items_text'}>
              {t('home_screen_services_software_maintenance_text')}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
            <img src={ConsultingIcon} alt="ConsultingIcon" />
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_texts'}
          >
            <Typography className={'services_items_heading'}>
              {t('home_screen_services_consulting_heading')}
            </Typography>
            <Typography className={'services_items_text'}>
              {t('home_screen_services_consulting_text')}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={4} md={1} lg={1} xl={1}></Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={5}
            className={'services_button'}
          >
            <Button
              onClick={handleButtonClick}
              variant="outlined"
              sx={{
                padding: '20px 24px',
                textAlign: 'center',
                borderRadius: '0px',
                border: '1px solid #FFF',
                color: '#FFF',
                textTransform: 'initial',
              }}
            >
              {t('home_screen_services_button')}
              <ArrowForwardIcon sx={{ marginLeft: '1rem' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Services
