import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.png'
import Singularity from '../components/About/Singularity'
import Team from '../components/About/Team'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import '../styles/About/About.css'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const About: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <>
        <Grid container className={'about_container'}>
          <img src={PageHeroBg} alt="Logo" className={'about_image_mobile'} />
          <Grid item className={'about_grid'} xs={12}>
            <div className={'about_subheading'}>
              {t('about_screen_subheading')}
            </div>
            <div className={'about_heading'}>{t('about_screen_heading')}</div>
            <div className={'about_text'}>{t('about_screen_text')}</div>
          </Grid>
        </Grid>
        <Singularity />
        <Team />
        <ContactUs />
      </>
    )
  }

  return (
    <>
      <Grid container className={'about_container'}>
        <img src={PageHeroBg} alt="Logo" className={'about_image'} />
        <Grid item className={'about_grid'} xs={12}>
          <div className={'about_subheading'}>
            {t('about_screen_subheading')}
          </div>
          <div className={'about_heading'}>{t('about_screen_heading')}</div>
          <div className={'about_text'}>{t('about_screen_text')}</div>
        </Grid>
      </Grid>
      <Singularity />
      <Team />
      <ContactUs />
      <Icons />
    </>
  )
}

export default About
