import React from 'react'
import Grid from '@mui/material/Grid'
import '../../styles/Services/Pricing.css'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'

const Pricing: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const grayText = t('services_pricing_text').split('\n\n')[0]
  const whiteText = t('services_pricing_text').split('\n\n')[2]
  const additionalGrayText = t('services_pricing_text').split('\n\n')[1]

  if (isMobile) {
    return (
      <>
        <Grid container className={'pricing_container'}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <div
              style={{
                padding: '1rem 0 1rem 0',
                fontSize: '48px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '60px',
                textAlign: 'center',
                color: '#FFF',
              }}
            >
              {t('services_pricing_heading')}
            </div>
            <div
              style={{
                backgroundColor: '#0076FF',
                height: '2px',
                width: '64px',
              }}
            ></div>
            <span
              style={{
                marginTop: '1rem',
                fontSize: '16px',
                fontWeight: '300',
                fontStyle: 'normal',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#AAA',
              }}
            >
              {grayText}
            </span>
            <span
              style={{
                marginTop: '1rem',
                fontSize: '16px',
                fontWeight: '300',
                fontStyle: 'normal',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#AAA',
              }}
            >
              {additionalGrayText}
            </span>
            <span
              style={{
                marginTop: '1rem',
                fontSize: '20px',
                fontWeight: '300',
                fontStyle: 'normal',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#FFF',
              }}
            >
              {whiteText}
            </span>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container className={'pricing_container'}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <div
            style={{
              padding: '1rem 0 1rem 0',
              fontSize: '48px',
              fontWeight: '400',
              fontStyle: 'normal',
              lineHeight: '60px',
              textAlign: 'center',
              color: '#FFF',
              whiteSpace: 'pre-line',
            }}
          >
            {t('services_pricing_heading')}
          </div>
          <div
            style={{
              backgroundColor: '#0076FF',
              height: '2px',
              width: '64px',
            }}
          ></div>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '16px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#AAA',
              whiteSpace: 'pre-line',
            }}
          >
            {grayText}
          </span>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '16px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#AAA',
              whiteSpace: 'pre-line',
            }}
          >
            {additionalGrayText}
          </span>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '20px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#FFF',
              whiteSpace: 'pre-line',
            }}
          >
            {whiteText}
          </span>
        </Grid>
      </Grid>
    </>
  )
}

export default Pricing
