import OurWork from '../components/Home/OurWork'
import About from '../components/Home/About'
import Services from '../components/Home/Services'
import SingularityStartup from '../components/Home/SingularityStartup'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import HomePageHeroBg from '../assets/homepage-hero-bg.png'
import PageHeroBg from '../assets/page-hero-bg.png'
import '../styles/Home/Home.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const button_style = {
  display: 'inline-flex',
  padding: '20px 48px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  borderRadius: '0px',
  backgroundColor: '#0076FF',
  backdropFilter: 'blur(10px)',
  textTransform: 'initial',
}

const Home: React.FC = () => {
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const handleButtonClick = () => {
    router('/contact')
  }

  if (isMobile) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <img src={PageHeroBg} alt="Logo" className={'home_image1'} />
          </Grid>
          <Grid
            item
            xs={12}
            className={'home_mobile_background'}
            sx={{
              textAlign: 'center',
              paddingTop: '4rem',
              marginTop: '-10rem',
            }}
          >
            <div style={{ marginTop: '4rem' }}>
              <div className={'home_heading'}>
                {t('home_screen_subheading')}
              </div>
              <div className={'home_subheading'}>
                {t('home_screen_heading')}
              </div>
              <Button
                onClick={handleButtonClick}
                variant="contained"
                sx={button_style}
              >
                {t('home_screen_button')}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '2rem' }}>
            <OurWork />
            <About />
            <Services />
            <SingularityStartup />
            <div style={{ marginTop: '10rem' }}></div>
            <ContactUs />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <img src={PageHeroBg} alt="Logo" className={'home_image1'} />
          <img src={HomePageHeroBg} alt="Logo" className={'home_image2'} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}
          sx={{ textAlign: 'center', paddingTop: '12rem' }}
        >
          <div className={'home_heading'}>{t('home_screen_subheading')}</div>
          <div className={'home_subheading'}>{t('home_screen_heading')}</div>
          <Button
            onClick={handleButtonClick}
            variant="contained"
            sx={button_style}
          >
            {t('home_screen_button')}
          </Button>
        </Grid>
        <Grid item lg={12} md={12} xs={12} sm={12} sx={{ paddingTop: '12rem' }}>
          <OurWork />
          <About />
          <Services />
          <SingularityStartup />
          <div style={{ marginTop: '10rem' }}></div>
          <ContactUs />
          <Icons />
        </Grid>
      </Grid>
    </>
  )
}

export default Home
